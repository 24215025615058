<template>
  <div style="padding:0 60px;margin-top: 40px;">
    <div v-show="tableData.length">
      <el-table :data="tableData" border style="width: 100%" header-cell-class-name="headerStyle1"
        :span-method="arraySpanMethod" :cell-class-name="cellStyle">
        <el-table-column label="商品图片" width="126" class-name="goods-cover-wrapper">
          <template slot-scope="{row}">
            <el-popover placement="right" trigger="hover">
              <img :src="row.web_thumbnail || row.thumbnail" alt="" style="width: 300px">
              <div slot="reference" @click="opengoodsclassify(row)">
                <img :src="row.web_thumbnail || row.thumbnail" class="goods-cover" alt="" />
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column label="商品名称">
          <template slot-scope="{row}">
            <!-- <div class="ellipsis-multiline" style="text-align: start;">
              {{ row?.name?.replaceAll('amp;', '')?.replaceAll(' ', '&nbsp;') }}
            </div> -->
            <!-- tips悬浮提示 -->
            <el-tooltip placement="top" v-model="row.showTooltip" :open-delay="500" effect="dark"
              :disabled="!row.showTooltip">
              <div slot="content">{{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}</div>
              <div @mouseenter="showTips($event, row)" class='ellipsis-multiline'>
                {{ row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;') }}
              </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" width="191"></el-table-column>
        <el-table-column prop="price" label="单价" width="191">
          <template slot-scope="{row}">
            <div v-if="row.type === 'order_sn'">
              <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px;">
                <div style="font-weight: 600;">订单编号：{{ row.trade_sn }}</div>
                <div style="">收货人信息：
                  {{ row.name + '&nbsp&nbsp' +
                    row.mobile + '&nbsp&nbsp' +
                    (row.province || '') + (row.city || '') + (row.county || '') + (row.town || '') + (row.address ||
                      '') }}
                </div>
              </div>
            </div>
            <div v-else-if="row.type === 'order_remark'">
              <div style="display: flex;justify-content: space-between;align-items: center;padding-right: 10px;">
                <div>订单备注：{{ row.order_remark }}</div>
                <div>
                  <span>商品总金额：{{ row.enterprise_goods_price }}</span>
                  <span style="margin: 0 20px 0 10px;">运费： {{ row.enterprise_freight_price }}</span>
                  <span>订单总金额：{{ row.enterprise_total_price }}</span>

                </div>
              </div>
            </div>
            <div v-else-if="row.type === 'empty'"></div>
            <div v-else>{{ row.enterprise_revise_price }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="enterprise_subtotal" label="金额" width="191"></el-table-column>
      </el-table>
    </div>
    <div class="submit">
      <div class="balance">
        <div class="balance-left">
          <span>
            总计订单：{{ cartsInfo.data.order_num }}个 商品金额总计：{{ cartsInfo.data.goods_money }} 运费金额总计：{{
              cartsInfo.data.freight
            }}
          </span>
        </div>
        <div class="dingdan">
          <div :style="{
            fontSize: '14px',
          }" class="order">
            <span>订单金额总计：</span>
            <span style="color: #b8741a;font-size: 20px;">{{ cartsInfo.data.order_money }}</span>
          </div>
          <div class="zhandian">
            <span>站点账户余额：</span>
            <span style="color: #b8741a;">{{ balance_account }}</span>
          </div>
        </div>
      </div>
      <div class="button" @click="orderPay">
        <div>确认支付</div>
        <div style="display: flex; font-size: 12px;color: #000;">
          <el-statistic @finish="hilarity" format="HH:mm:ss" prefix="(" suffix="自动取消)"
            :value="Date.now() + (((cartsInfo.data.trade_batch_do.create_time || 0) * 1000 + 24 * 60 * 60 * 1000) - Date.now())"
            time-indices>
          </el-statistic>
        </div>
      </div>
    </div>
    <loading v-if="isLoading">订单支付中...</loading>
  </div>
</template>

<script>
import { getOrderList, payTrade, getShopBalance } from '@/api/shopGoodsPurchase'
import Loading from '@/components/Loading'
export default {
  name: 'ManagerOneClickDeliverySubmitOrder',
  components: { Loading },
  props: {
    batch_sn: { type: String, default: '' },
  },
  data () {
    return {
      tableData: [],
      cartsInfo: { data: { trade_batch_do: {} } },
      balance_account: '',
      isLoading: false
    };
  },

  mounted () {
    this.handleTableData()
    this.getShopBalance()
  },

  methods: {
    showTips (obj, row) {
      /*obj为鼠标移入时的事件对象*/

      /*currentWidth 为文本在页面中所占的宽度，创建标签，加入到页面，获取currentWidth ,最后在移除*/
      let TemporaryTag = document.createElement('span');
      TemporaryTag.innerText = row.name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;')
      TemporaryTag.className = 'getTextWidth';
      document.querySelector('body').appendChild(TemporaryTag);
      let currentWidth = document.querySelector('.getTextWidth').offsetWidth;
      document.querySelector('.getTextWidth').remove();

      /*cellWidth为表格容器的宽度*/
      const cellWidth = obj.target.offsetWidth
      /*当文本宽度小于||等于容器宽度两倍时，代表文本显示未超过两行*/
      currentWidth <= (2 * cellWidth) ? row.showTooltip = false : row.showTooltip = true
    },
    /**获取订单车列表 */
    async handleTableData () {
      if (!this.batch_sn) return
      const res = await getOrderList(this.batch_sn)
      const list = []
      this.cartsInfo = Object.freeze(res)
      this.cartsInfo.data.normal_order.forEach((item, index) => {
        list.push({ type: 'order_sn', trade_sn: item.trade_sn, ...item.consignee })
        item.order_list.forEach(item => { item.sku_list.forEach(item => list.push(item)) })
        const order_remark = item.order_list?.[0]?.remark ?? ''
        list.push({ type: 'order_remark', ...item.price_detail, order_remark })
        if (index !== this.cartsInfo.data.normal_order.length - 1) list.push({ type: 'empty' })
      })
      this.tableData = list
    },
    /**获取站点账户余额 */
    async getShopBalance () {
      const res = await getShopBalance()
      this.balance_account = res.balance_account
    },
    arraySpanMethod ({ row, column, rowIndex, columnIndex }) {
      if (!row.type) return
      if (columnIndex === 3) return [1, 5]
      return [0, 0]
    },
    cellStyle ({ row, column, rowIndex, columnIndex }) {
      if (!row.type) return 'cellStyle'
      const type = {
        order_sn: 'cellStyle2',
        order_remark: 'cellStyle2',
        empty: ''
      }
      return type[row.type]
    },
    /**订单支付 */
    async orderPay () {
      try {
        this.isLoading = true
        const res = await payTrade({ batch_sn: this.batch_sn })
        this.isLoading = false
        if (res.code === -1) {
          try {
            await this.$confirm(res.message, '提示', { type: 'warning', confirmButtonText: '立即充值', cancelButtonText: '暂不充值', })
            this.$router.push({ path: '/finance/shop-overview' })
          } catch (error) {

          }
          return
        }
        if (res.code === 200) {
          if (res?.err_order?.length) {
            try {
              await this.$confirm(res.message, '提示', { type: 'success', confirmButtonText: '查看订单', cancelButtonText: '立即充值', })
              this.$router.push({ path: '/order/order-list' })
            } catch (error) {
              this.$router.push({ path: '/finance/shop-overview' })
            }
          } else {
            this.$confirm(res.message, '提示', {
              type: 'success', confirmButtonText: '查看订单', cancelButtonText: '继续选购', distinguishCancelAndClose: true, beforeClose: (action, instance, done) => {
                console.log(action, instance, done);
                if (action === 'confirm') {
                  this.$router.push({ path: '/order/order-list' })
                  done()
                }
                if (action === 'cancel') {
                  this.$router.push({ path: '/shop-goods/shop-goods-purchase' })
                  done()
                }

                if (action === 'close') {
                  console.log('跳转到商品详情 暂时接口还没有返回订单信息');


                  this.$router.push({
                    // path: `/order/order-list/detail/${JSON.parse(this.cartsInfo.data.trade_batch_do.ext_msg).normal_order[0].trade_sn}?type=1`
                    path: `/order/importOrderDetail?batch_sn=${this.batch_sn}`
                  });
                  done()
                }
              }
            })
          }

          return
        }
      } catch (error) {
        this.isLoading = false
      }
    },
    hilarity () { }
  },
};
</script>
<style lang="scss">
.headerStyle1 {
  font-weight: 400;
  background-color: #f3f3f3 !important;

  &>div {
    text-align: center;
    color: #333;
  }

}

.cellStyle {
  &>div {
    text-align: center;
  }
}

.cellStyle2 {
  // background-color: #d7d7d7 !important;

  &>div {
    text-align: center;
  }
}
</style>
<style lang="scss" scoped>
.goods-cover-wrapper {
  padding: 0;

  .cell {
    padding: 0;
    text-align: center;

    span,
    a {
      display: inline-block;
    }
  }

  .goods-cover {
    width: 52px;
    height: 52px;
    object-fit: cover;
    object-position: center;
    border-radius: 4px;
  }
}

.submit {
  height: 61px;
  margin-top: 8px;
  display: flex;

  .balance {
    flex: 1;
    display: flex;
    justify-content: space-between;

    &-left {
      line-height: 61px;
    }

    .dingdan {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .order {
      margin-right: 10px;
    }

    .zhandian {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding-bottom: 5px;
      margin-right: 10px;
      font-weight: 400;
    }
  }

  .button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 152px;
    height: 100%;
    font-size: 16px;
    // line-height: 61px;
    text-align: center;
    background-color: #b8741a;
    color: #fff;
    cursor: pointer;

    ::v-deep .el-statistic {
      width: fit-content;
      color: #fff;

      .con {
        width: fit-content;
        color: #fff;

        .number {
          font-size: 12px;
          color: #fff;
        }
      }
    }

  }
}
</style>